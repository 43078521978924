@import "bubbles-module/styles/icons";

.user{
  padding: 15px;
  border-radius: 8px;
  border: var(--stroke);
  display: grid;
  gap: 10px;
  grid-template-columns: max-content auto max-content;
  position: relative;
  &__avatar {
    border-radius: 4px;
    width: 64px;
    height: 64px;
    background-size: cover;
    &._placeholder {
      background-image: $avatarPlaceholder;
    }
  }
  &__info {
    p {
      line-height: 1;
      height: 1em;
    }
  }
  &__username {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
  }
  &__stats {
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--text-secondary-0)
  }
}
