.auth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
  :global(.uiButton),
  button{
    padding: 0 15px;
  }
  p{
    font-weight: 500;
    font-size: 1.2em;
  }
}

.success {
  &__promotion {
    display: flex;
    align-items: center;
    gap: 3px;
    text-align: center;
  }
  &__icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: limegreen;
    color: #F3F6F8;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
